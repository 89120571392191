import cc from "classnames"
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react"
import { A11y } from "swiper/modules"

import { $auth } from "@stores/auth"
import { useStore } from "@nanostores/react"

import Container from "@components/Container"
import Image from "@components/Image"
import SpacingContainer from "@components/SpacingContainer"
import CmsButton from "@components/CmsButton"
import { ReactComponent as Instagram } from "@icons/instagram.svg"

interface InstagramPost {
  media: Domain.Shared.DataTransferObjects.MediaResourceData
  postLink: string
}
export interface InstagramTeaserProps extends Domain.Shared.DataTransferObjects.BlockBaseConfigData {
  instagram_posts: InstagramPost[]
  headline: string
  backgroundColor: Domain.Shared.Enums.ColorPaletteEnum
  shouldHideLinkOnDesktop?: boolean
}

const InstagramTeaser = (props: InstagramTeaserProps): JSX.Element => {
  const { margin_top, margin_bottom, padding_top, padding_bottom, backgroundColor } = props
  const spacingMarginTop = margin_top?.value ?? "md"
  const spacingMarginBottom = margin_bottom?.value ?? "none"
  const spacingPaddingTop = padding_top?.value ?? "none"
  const spacingPaddingBottom = padding_bottom?.value ?? "none"

  const { t } = useTranslation<string>("common")

  const { user } = useStore($auth)
  const isHidden = (props.hide_for_logged_in_users && user) || (props.hide_for_logged_out_users && !user)

  if (isHidden) return <></>

  return (
    <SpacingContainer
      margin_top={spacingMarginTop}
      margin_bottom={spacingMarginBottom}
      padding_top={spacingPaddingTop}
      padding_bottom={spacingPaddingBottom}
      background_color={backgroundColor}
      class_name="overflow-hidden"
    >
      <Container>
        <div className="mb-24 md:mb-32">
          <a href="https://instagram.com/happyplates_com" target="_blank" rel="noreferrer">
            <div className="flex items-center">
              <h2 className="pr-8 text-20 font-semibold leading-[120%] md:text-32 md:leading-48">{props.headline}</h2>
              <div
                className={cc("ml-auto", {
                  "md:hidden": props.shouldHideLinkOnDesktop,
                })}
              >
                <CmsButton appearance="rounded" text={t("common.learnMore") ?? ""} />
              </div>
            </div>
          </a>
        </div>
        <div className="hidden md:grid md:grid-cols-24 md:gap-x-16">
          {props.instagram_posts.map((post, index) => (
            <div className="col-span-8" key={`${post.media.permalink}-${index}`}>
              <a
                href={post.postLink ?? "https://instagram.com/happyplates_com"}
                target="_blank"
                rel="noopener noreferrer"
                className="group relative block"
              >
                <div className="aspect-h-13 aspect-w-10 overflow-hidden rounded-2">
                  <Image
                    src={post.media.permalink}
                    span={4}
                    alt={post.media.alt}
                    focal_points={post.media.focus}
                    aspect_ratio="10:13"
                    loading={props.is_above_fold ? "eager" : undefined}
                    className="object-cover transition-all duration-200 ease-out group-hover:scale-[1.03]"
                    fill
                  />
                </div>
                <div className="instagram-gradient-background absolute left-0 top-0 h-full w-full opacity-0 transition-all duration-150 group-hover:opacity-100 "></div>
                <div className="absolute -bottom-16 left-0 z-10 flex w-full items-center p-24 text-white opacity-0 transition-all duration-150 group-hover:bottom-0 group-hover:opacity-100">
                  <div className="mr-8 inline-block h-16 w-16">
                    <Instagram className="text-white" />
                  </div>
                  <span className="inline-block font-medium">happyplates_com</span>
                </div>
              </a>
            </div>
          ))}
        </div>
        <div className="md:hidden">
          <Swiper
            slideToClickedSlide={true}
            spaceBetween={16}
            slidesPerView={1.2}
            style={{ overflow: "visible" }}
            modules={[A11y]}
          >
            {props.instagram_posts.map((post, index) => (
              <SwiperSlide key={`${post.media.permalink}-${index}`}>
                <a
                  href="https://instagram.com/happyplates_com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group relative block"
                >
                  <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-2">
                    <Image
                      src={post.media.permalink}
                      span={12}
                      alt={post.media.alt}
                      focal_points={post.media.focus}
                      aspect_ratio="1:1"
                      className="object-cover"
                      fill
                    />
                  </div>
                  <div className="instagram-gradient-background absolute left-0 top-0 h-full w-full"></div>
                  <div className="absolute bottom-0 left-0 z-10 flex w-full items-center p-12 text-white">
                    <div className="mr-8 inline-block h-16 w-16">
                      <Instagram className="text-white" />
                    </div>
                    <span className="inline-block">happyplates_com</span>
                  </div>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </SpacingContainer>
  )
}

export default InstagramTeaser
